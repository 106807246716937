import { SET_DELIVERY_PAYMENTS } from '../actions/services';

const INITIAL_STATE = {
  deliveryPayments: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DELIVERY_PAYMENTS:
      // eslint-disable-next-line no-console
      console.log({ action });
      return { ...state, deliveryPayments: action.payload };

    default:
      return state;
  }
}
