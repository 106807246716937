import { API } from './index';
import { logOut } from './loginActions';

export const SET_DELIVERY_PAYMENTS = 'SET_DELIVERY_PAYMENTS';

export const fetchPaymentDelivery = () => async (dispatch, getState) => {
  const request = await fetch(
    `${API.url}/admin/services/paymentDelivery?withStatus=true`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().login.token
      }
    }
  );

  const status = await request.status;

  const { data } = await request.json();

  switch (status) {
    case 200:
      dispatch({
        type: SET_DELIVERY_PAYMENTS,
        payload: data
      });
      break;
    case 401:
      dispatch(logOut());
      break;
    case 500:
      alert('Ошибка сервера');
      break;

    default:
      alert(`Ошибка ${status}`);
      break;
  }
};
export const changePaymentDelivery =
  (id, state) => async (dispatch, getState) => {
    const request = await fetch(
      `${API.url}/admin/services/changePaymentDelivery?withStatus=true`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getState().login.token
        },
        body: JSON.stringify({
          id,
          state
        })
      }
    );

    const status = await request.status;

    switch (status) {
      case 200:
        dispatch(fetchPaymentDelivery());
        break;
      case 401:
        dispatch(logOut());
        break;
      case 500:
        alert('Ошибка сервера');
        break;

      default:
        alert(`Ошибка ${status}`);
        break;
    }
  };
