import arrayMove from 'array-move';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchProduct,
  settingProductModifiers
} from '../actions/productActions';
import Loader from '../components/interface/loader';
import { HeaderPage, SvgButton, Table } from '../components/elements/elements';

class OptionalModifiersProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      modifiers: [],
      actives: [],
      productId: ''
    };
  }

  componentDidMount() {
    const { productId } = this.props.match.params;

    if (productId) {
      this.props.fetchProduct(productId, this.setStateModifiers);
    }
  }

  setStateModifiers = (product) => {
    const modifiers = [];
    const actives = product.editModifiers;

    product.optionalModifiers.forEach((optionalModifier) => {
      modifiers.push(...optionalModifier.modifiers);
    });

    const productId = product.customGroup._id;

    this.setState({ modifiers, actives, productId });
  };

  getTitles = () => {
    return ['Изображение', 'Название', 'Цена', ''];
  };

  findModifierIndex = (item) =>
    this.state.actives.findIndex(
      (p) => (p.id || p._id) === (item.id || item._id)
    );

  onChangeActives = (modifier, isRemove) => {
    return () => {
      const actives = [...this.state.actives];
      const matchId = this.findModifierIndex(modifier);

      if (isRemove) {
        actives.splice(matchId, 1);
      } else {
        if (matchId !== -1) {
          return alert('Модификатор уже активен');
        }
        actives.push(modifier);
      }

      this.setState({ actives });
    };
  };

  onSort = (product, isTop) => {
    return () => {
      const actives = [...this.state.actives];
      const index = this.findModifierIndex(product);

      this.setState({
        actives: arrayMove(actives, index, isTop ? index - 1 : index + 1)
      });
    };
  };

  onSave = async () => {
    const { actives } = this.state;

    if (actives.length < 3 || actives.length > 4) {
      return alert('Необходимо выбрать от 3-х до 4-х модификаторов');
    }

    const { productId } = this.state;

    try {
      this.props.settingProductModifiers(
        productId,
        {
          customGroupModifiersIds: await Promise.all(
            this.state.actives.map(async (item) => {
              const modifierGroup = await this.props.fetchProduct(
                item.id || item._id
              );
              if (!modifierGroup.customGroup) {
                alert(`Модификатор ${item.name} нельзя добавить.`);
                throw new Error();
              }
              return modifierGroup.customGroup._id;
            })
          )
        },
        () => alert('Модификаторы успешно сохранены')
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getItems = () => {
    const array = [];
    const { categories } = this.props;
    if (categories)
      categories.map((category) =>
        array.push({
          value: category.id,
          text: category.name
        })
      );
    return array;
  };

  renderTableRow = (items, isActives) =>
    items.map((item) => {
      const href = item.images
        ? `https://${process.env.REACT_APP_BASE_URL}${item.images[0]}`
        : '';

      return (
        <tr key={item._id}>
          <td>
            {href && (
              <a href={href}>
                <img
                  alt=''
                  src={href}
                  style={{
                    width: '100px',
                    height: '100px',
                    objectFit: 'cover',
                    objectPosition: 'center'
                  }}
                />
              </a>
            )}
          </td>
          <td>{item.name}</td>
          <td>{item.price}</td>
          <td>
            <div
              style={{ display: 'flex', float: 'right', alignItems: 'center' }}
            >
              {isActives && (
                <div className='recommended-sort'>
                  <SvgButton
                    image='arrow'
                    onClick={this.onSort(item, true)}
                  />
                  <SvgButton
                    image='arrow'
                    onClick={this.onSort(item)}
                  />
                </div>
              )}
              <SvgButton
                image={isActives ? 'delete' : 'add'}
                onClick={this.onChangeActives(item, isActives)}
              />
            </div>
          </td>
        </tr>
      );
    });

  renderActivesModifiers = () => {
    const { actives } = this.state;

    return (
      <div className='recommended'>
        <HeaderPage
          little
          title='Активные'
        />
        <Table
          data={this.renderTableRow(actives, true)}
          title={this.getTitles()}
        />
      </div>
    );
  };

  renderModifiersTable = () => {
    const { modifiers } = this.state;

    return (
      <Table
        data={this.renderTableRow(modifiers)}
        title={this.getTitles()}
      />
    );
  };

  renderModifiersList = () => {
    return (
      <>
        <HeaderPage
          little
          title='Список модификаторов'
        />
        {this.renderModifiersTable()}
      </>
    );
  };

  render() {
    const { update } = this.state;

    if (update) return <Loader />;

    return (
      <div>
        <HeaderPage
          button='Сохранить'
          onClick={this.onSave}
          title='К этому блюду подойдет'
        />

        {this.renderActivesModifiers()}

        {this.renderModifiersList()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    product: state.products.product
  };
}

export default connect(mapStateToProps, {
  fetchProduct,
  settingProductModifiers
})(OptionalModifiersProduct);
